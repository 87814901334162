import moment from "moment"

export const reduceObjects = function (array, property) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property]
  }, 0)

  return total
}

export const isBrowser = () => typeof window !== "undefined"

// Dynamically build img url based on .env - prod uses a CDN and dev is local.
export const imageUrl = (url) => {
  console.log("IMAGE", { url, env: process.env.NODE_ENV })
  if (process.env.NODE_ENV !== "production") {
    return process.env.GATSBY_APP_STRAPI_API_URL + url
  } else {
    return url
  }
}

export const imageFormat = (image, small) => {
  const imgFormats = []

  const imgUrl = image?.url || ''
  const imgLarge = image?.formats?.large?.url
  const imgMedium = image?.formats?.medium?.url
  const imgSmall = image?.formats?.small?.url
  const imgThumb = image?.formats?.thumbnail?.url

  if (imgUrl) imgFormats.push(imgUrl)
  if (imgLarge) imgFormats.push(imgLarge)
  if (imgMedium) imgFormats.push(imgMedium)
  if (imgSmall) imgFormats.push(imgSmall)
  if (imgThumb) imgFormats.push(imgThumb)

  let url = ""

  if (small) {
    url = imgFormats[imgFormats.length - 1]
  } else {
    url = imgFormats[0]
  }

  console.log('image return url', url)

  if (process.env.NODE_ENV !== "production") {
    return url
    // return process.env.GATSBY_APP_STRAPI_API_URL + url
  } else {
    return url
  }
}

export const dateFormat = (date) =>
  new Date(date).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  })

export const dateTimeFormat = (date) =>
  new Date(date).toUTCString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  })

export const formatCapitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const sort = (array, property) =>
  array.sort(function (a, b) {
    return new Date(a[property]) - new Date(b[property])
  })

export const isRegistrationOpen = (event) => {
  if (!event) return false

  // check max users


  if (!(!event.users || event.users?.length < event?.max_users)) return false

  const start = new Date(event.reg_start)
  const end = new Date(event.reg_end)
  const today = new Date()

  console.log('check', event, start, end)

  if (!start || !end) return true

  if (today >= start && today <= end) {
    return true
  }
}

export const isFundraiserRegistrationOpen = (fundraiser) => {
  if (!fundraiser) return false

  const start = fundraiser.reg_start
  const end = fundraiser.reg_end
  const today = new Date()

  if (!start || !end) return true

  if (today >= new Date(start) && today <= new Date(end)) {
    return true
  }
}

export const sortDateNewest = (array) => {
  console.log(array);

  // Check if the input is an array and is not empty
  if (!Array.isArray(array) || array.length === 0) {
    console.warn('Invalid input: Expected a non-empty array.');
    return;
  } else {
    return array.reduce((a, b) => (a.date > b.date ? a : b));
  }
}

export const sortTeeTimes = (arr) =>
  arr
    .map((c) => {
      var time = c.time.substring(0, c.time.length - 2)
      var am_pm = c.time.slice(-2)

      var hours = parseInt(time.split(":")[0])
      var minutes = parseInt(time.split(":")[1])

      if (hours === 12 && am_pm.toLowerCase() === "am") {
        hours = 0
      } else if (hours < 12 && am_pm.toLowerCase() === "pm") {
        hours += 12
      }

      // save hours and minutes
      c.hours = hours
      c.minutes = minutes

      return c
    })
    .sort((a, b) => {
      return a.hours * 100 + a.minutes - (b.hours * 100 + b.minutes)
    })

export const hours = (arr) =>
  arr.map((c) => {
    var time = c.time.substring(0, c.time.length - 2)
    var am_pm = c.time.slice(-2)

    var hours = parseInt(time.split(":")[0])
    var minutes = parseInt(time.split(":")[1])

    if (hours === 12 && am_pm.toLowerCase() === "am") {
      hours = 0
    } else if (hours < 12 && am_pm.toLowerCase() === "pm") {
      hours += 12
    }

    // save hours and minutes
    c.hours = hours
    c.minutes = minutes
    c.combind =
      (hours.length < 2 ? "0" + hours : hours) +
      ":" +
      (minutes.length < 2 ? "0" + minutes : minutes) +
      ":00"

    return c
  })

// export const getBirthday = (date) => {
//   var years = moment().diff(new Date(date), "years") || "---"
//   return years
// }

export const getBirthday = (date, currentDate = moment()) => {
  const birthday = new Date(date);
  const referenceDate = moment(currentDate);  // Use the provided currentDate or default to the current system date
  var years = referenceDate.diff(birthday, "years");
  return years || "---";  // Return years or "---" if calculation fails
}

export const dateFormatHour = (string) => {
  var date = new Date(moment(string))
  var options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }
  var timeString = date.toLocaleString("en-US", options)
  console.log(timeString)

  return timeString
}
