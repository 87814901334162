import { createTheme } from "@mui/material/styles"
import { blue, lightBlue, deepOrange, red, indigo } from "@mui/material/colors"

console.log(`[theme.js] Initialize custom theme.`)

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: lightBlue[900],
    },
    secondary: {
      main: deepOrange[500],
    },
    // info: {
    //   main: lightBlue[500],
    // },
    // success: {
    //   main: "#43aa8b",
    //   contrastText: "#000",
    // },
    // warning: {
    //   main: "#f9c74f",
    //   contrastText: "#000",
    // },
    error: {
      main: red[900],
    },
  },
  components: {
    MuiGrid2: {
      defaultProps: {
        spacing: 3,
        disableEqualOverflow: false,
      },
    },
  },
})

export default theme
