import React, { useEffect, useState } from "react"
import { isBrowser } from "../../util"
import {
  Container,
  Typography,
  Box,
  Modal,
  Button,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material"
import { getAnnouncements } from "../../services/announcements"

const announcementSeverityStyle = (severity) => {
  switch (severity) {
    case "Medium":
      return "warning"
    case "High":
      return "error"
    default:
      return "info"
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const Banner = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [open, setOpen] = useState(false)
  const [announcements, setAnnouncements] = useState([])

  console.log(announcements);

  const currentDate = new Date();

  const filteredArray = announcements.filter(item => {
    const itemDate = new Date(item.attributes.date_end);
    console.log({ currentDate, itemDate });
    return itemDate > currentDate;
  });

  console.log('filtered', filteredArray);

  useEffect(() => {
    getAnnouncements()
      .then((res) => {
        if (
          res.data.some(
            (announcement) => announcement.attributes.urgency === "High"
          )
        ) {
          setOpen(true)
        }
        setAnnouncements(res.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const dismissBanner = () => {
    if (isBrowser()) {
      setIsVisible(false)
    }
  }

  if (!isVisible) return null

  if (filteredArray.length === 0) return null;

  return (
    <Box component="div">
      <Box
        component="div"
        sx={{
          backgroundColor:
          filteredArray.length === 0 ? "info.main" : "secondary.main",
          borderRadius: "0",
        }}
      >
        <Container sx={{ color: "error.contrastText", pt: 1, pb: 1 }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <strong>Announcements ({filteredArray.length})</strong>
            <Box component="span">
              <Button sx={{ color: "error.contrastText" }} onClick={toggleOpen}>
                View
              </Button>
              <Button
                sx={{ color: "error.contrastText" }}
                onClick={dismissBanner}
              >
                Dismiss
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Modal
        open={open}
        onClose={toggleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="div" sx={style}>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            Announcements
          </Typography>

          <hr />
          <br />
          <Stack spacing={2}>
            {filteredArray.length !== 0 ? (
              filteredArray.map((announcement) => {
                return (
                  <Alert
                    key={announcement.attributes.name}
                    severity={announcementSeverityStyle(
                      announcement.attributes.urgency
                    )}
                  >
                    <AlertTitle>{announcement.attributes.name}</AlertTitle>
                    {announcement.attributes.text}
                  </Alert>
                )
              })
            ) : (
              <Alert severity={announcementSeverityStyle()}>
                <AlertTitle>There are no new announcements.</AlertTitle>
                Check back frequently for news pertaining to the tour, event
                updates, and more!
              </Alert>
            )}
          </Stack>
        </Box>
      </Modal>
    </Box>
  )
}

export default Banner
